import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js'
import { helpers } from 'vuelidate/lib/validators'
import { stripNonPhoneDigits } from '@/util/phoneNumber'

function parseUsPhoneNumber(str: string): PhoneNumber | undefined {
  return parsePhoneNumberFromString(str, 'US')
}

function usPhoneNumberE164(str: string): string {
  const phoneNumber = parseUsPhoneNumber(str)
  if (phoneNumber && phoneNumber.isValid()) {
    return phoneNumber.number.toString()
  } else {
    return ''
  }
}

export function usPhoneNumber(str: string): boolean {
  const phoneNumber = parseUsPhoneNumber(str)
  return phoneNumber ? phoneNumber.isValid() : !helpers.req(str)
}

export function usPhoneNumberDigits(str: string): string {
  const phoneNumber = usPhoneNumberE164(str)
  return stripNonPhoneDigits(phoneNumber)
}

export function phoneNumberMask(value: string): string {
  if (value && value.startsWith('1')) {
    return '#-###-###-####'
  } else {
    return '###-###-####'
  }
}
